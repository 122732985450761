import * as React from 'react';
import CommonLayout from '../templates/CommonLayout';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <CommonLayout>
    <Seo title="準備中" />
    <p>お探しのページは現在準備中です。</p>
    <p>今しばらくお待ち下さい。</p>
  </CommonLayout>
);

export default NotFoundPage;
